$breakpoint: 800px;
$itemMargin: 2.5rem;

$color-brilliant-azure: #39f;
$color-bara-red: #f0506e;
$color-black: #000;
$color-pale-grey: #fefefe;
$color-silver: #c0c0c0;
$color-zhen-zhu-bai-pearl: #f8f8f8;

$base-background-color: $color-pale-grey;

$navbar-background-color: $color-zhen-zhu-bai-pearl;
$navbar-height: 5rem;
$navbar-text-color: $color-black;

$links-color: $color-brilliant-azure;
