body {
  > footer {
    align-items: right;
    color: $navbar-text-color;
    display: flex;
    font-size: 0.8em;
    justify-content: space-between;
    padding: 0.5rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    // height: 3rem;
    // @media screen and (max-width: $breakpoint) {
    //   height: 5rem;
    // }

    @media screen and (max-width: $breakpoint) {
      flex-direction: column;

      p {
        &:first-of-type {
          margin-bottom: 0.1rem;
        }
        &:last-of-type {
          margin-top: 0.1rem;
        }
      }
    }
  }
}
