body {
  nav {
    align-items: center;
    background-color: #f4f5f6;
    color: $navbar-text-color;
    display: flex;
    height: $navbar-height;
    justify-content: space-between;
    left: 0;
    padding: 0.1rem 1rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    border-bottom: .1rem solid #d1d1d1;

    a {
      color: $navbar-text-color;
      text-decoration: none;

      &:hover, &:active, &:visited {
        text-decoration: none;
      }
    }

    &.animated {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      will-change: transform, opacity;

      &.slideDown {
        animation-name: slideDown;
      }

      &.slideUp {
        animation-name: slideUp;
      }
    }
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100%);
  }
}

@-moz-keyframes slideUp {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    -moz-transform: translateY(-100%);
  }
}

@-o-keyframes slideUp {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    -o-transform: translateY(-100%);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slideDown {
  0% {
    -moz-transform: translateY(-100%);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes slideDown {
  0% {
    -o-transform: translateY(-100%);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
