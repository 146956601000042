html, body {
  background-color: $base-background-color;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  margin: 0;
  text-rendering: optimizeLegibility;
}

body {
  display: grid;
  grid-gap: 1rem;
  grid-template-areas: "nav" "main" "footer";
  grid-template-columns: 1fr;
  height: 100%;

  grid-template-rows: $navbar-height 1fr 3rem;
  @media screen and (max-width: $breakpoint) {
    grid-template-rows: $navbar-height 1fr 5rem;
  }

  a {
    color: $links-color;
    text-decoration: none;

    &:hover, &:active, &:visited {
      text-decoration: underline;
    }
  }

  nav {
    grid-area: nav;
  }

  main {
    grid-area: main;
    justify-self: center;
    grid-column: 1 / -1;
    max-width: 80rem !important;
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;

    &.home {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  footer {
      grid-area: footer;
  }
}
